import React, { useRef } from "react";
import Form from "../../atoms/form/Form";
import Card from "../../atoms/card/Card";
import Text from "../../atoms/text/Text";
import { formatSimulationState } from "../../pages/simulations/lib/formatters";
import { GeneralDisplay } from "../../pages/simulations/steps/general";
import { PlayersDisplay } from "../../pages/simulations/steps/players";
import { ModuleDisplay } from "../../pages/simulations/steps/module";
import { RegionDisplay } from "../../pages/simulations/steps/region";
import { BusinessDisplay } from "../../pages/simulations/steps/business";
import { DealRoomDisplay } from "../../pages/simulations/steps/dealroom";
import { ExcoDecisionDisplay } from "../../pages/simulations/steps/excodecision";
import { SummaryDisplay } from "../../pages/simulations/steps/summary";
import { FinancialScreenDisplay } from "../../pages/simulations/steps/financial-summary";
import { SpecialProjectsDisplay } from "../../pages/simulations/steps/special-projects/Index";
import { HelpManualDisplay } from "../../pages/simulations/steps/resources";
import { MetadataDisplay } from "../../pages/simulations/steps/metadata";
import Printable from "../../atoms/printable/Printable";
import Button from "../../atoms/button/Button";
import PrintSummaryMetrics from "../../pages/simulations/steps/summary/PrintSummaryMetrics";
import PrintFinancialSummaryMetrics from "../../pages/simulations/steps/financial-summary/PrintFinancialSummary";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import PrintOnly from "../../atoms/printable/components/PrintOnly";

interface Props {
  simulation: API.SimulationResponse;
}

const SimulationPreviewWithData: React.FC<Props> = ({ simulation: data }) => {
  const printableRef = useRef<{ handlePrint: () => void }>(null);

  const handlePrint = () => {
    if (printableRef.current) {
      printableRef.current.handlePrint();
    }
  };

  return (
    <>
      <Printable ref={printableRef} visibleOnScreen={true}>
        <Form>
          <InlineGroup block right>
            <Button onClick={handlePrint}>Print Simulation</Button>
          </InlineGroup>
          <Card wide>
            <h4>Status</h4>
            <Text
              size="sm"
              bold
              colour={data.state === "draft" ? "danger" : "green"}
            >
              {formatSimulationState(data)}
            </Text>
          </Card>
          <GeneralDisplay data={data} />
          <PlayersDisplay data={data?.playersSetup} />
          <ModuleDisplay data={data} />
          <RegionDisplay data={data?.regionalSetup} />
          <BusinessDisplay data={data?.businessSetup} />
          <DealRoomDisplay data={data} />
          <ExcoDecisionDisplay data={data} />
          <VerticalGroup spaceBetweenElements={3} wide>
            <SummaryDisplay
              data={data?.summaryMetrics}
              config={data?.summaryMetricConfig}
            />
            <FinancialScreenDisplay
              data={data?.financialSummaryMetrics}
              config={data?.financialSummaryMetricConfig}
            />
          </VerticalGroup>
          <PrintOnly>
            <Card wide>
              <PrintSummaryMetrics
                data={data?.summaryMetrics}
                config={data?.summaryMetricConfig}
              />
            </Card>
            <Card wide>
              <PrintFinancialSummaryMetrics
                data={data?.financialSummaryMetrics}
                config={data?.financialSummaryMetricConfig}
              />
            </Card>
          </PrintOnly>
          <SpecialProjectsDisplay data={data?.specialProjectsSetup} />
          <VerticalGroup spaceBetweenElements={3} wide>
            <HelpManualDisplay data={data?.resources} />
          </VerticalGroup>
          <MetadataDisplay data={data?.metadata} />
        </Form>
      </Printable>
    </>
  );
};

export default SimulationPreviewWithData;
