import React, { useCallback, useEffect } from "react";
import API from "../../../services/api";
import Container from "../../atoms/page/Page";
import { useParams, useHistory } from "react-router-dom";
import GameStatus from "./GameStatus";
import OpenEventSidebar from "../../organisms/open-event-sidebar/OpenEventSidebar";
import useAPIRequest from "../../../hooks/useAPIRequest";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import EmptyList from "../../organisms/empty-list/EmptyList";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import useIsMobile from "../../../hooks/useIsMobile";

export const MINIMUM_SECONDS_LEFT = 10;

function GameController() {
  const { eventId } = useParams<{ eventId: string }>();
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleEventChange = useCallback(
    (gameId: string) => {
      history.push(`/games/${gameId}`);
    },
    [history],
  );

  const callback = useCallback(() => {
    return API.getEvent(eventId);
  }, [eventId]);

  const [
    { inProgress: eventFetchInProgress, data: event, error: eventError },
    fetchEvent,
  ] = useAPIRequest<API.EventResponse>(callback, null);

  const gameStatusCallback = useCallback(() => {
    return API.getGameStatus(eventId);
  }, [eventId]);

  const [
    {
      inProgress: gameStatusFetchInProgress,
      data: game,
      error: gameStatusError,
    },
    fetchGameStatus,
  ] = useAPIRequest<API.GameStatus>(gameStatusCallback, null);

  useEffect(() => {
    if (eventId) {
      fetchEvent();
      fetchGameStatus();
    }
  }, [fetchEvent, fetchGameStatus, eventId]);

  const error = eventError || gameStatusError;
  const hasCorrectEventData =
    event && game && event.id === eventId && game.gameId === eventId;
  const inProgress = eventFetchInProgress || gameStatusFetchInProgress;

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {isMobile ? (
        eventId ? (
          hasCorrectEventData ? (
            <Container full>
              <VerticalGroup full center>
                <GameStatus
                  key={eventId}
                  event={event!}
                  game={game!}
                  fetchGameStatus={fetchGameStatus}
                  handleEventChange={() => handleEventChange("")}
                  fetchEvent={fetchEvent}
                />
              </VerticalGroup>
            </Container>
          ) : (
            <Container full>
              <VerticalGroup full center>
                <LoadingSpinner />
              </VerticalGroup>
            </Container>
          )
        ) : (
          <OpenEventSidebar eventId={eventId} onChange={handleEventChange} />
        )
      ) : (
        <>
          <OpenEventSidebar eventId={eventId} onChange={handleEventChange} />
          <div style={{ flex: 1, width: "100%" }}>
            <Container>
              {error && (
                <Banner
                  type="error"
                  active={!!error}
                  message={error?.message}
                />
              )}
              {!!eventId && inProgress && !hasCorrectEventData && (
                <LoadingSpinner />
              )}
              {!eventId && (
                <EmptyList icon="gameController" message="Select a game" />
              )}
              {hasCorrectEventData && (
                <VerticalGroup wide full center>
                  <GameStatus
                    key={eventId}
                    event={event!}
                    game={game!}
                    fetchGameStatus={fetchGameStatus}
                    fetchEvent={fetchEvent}
                  />
                </VerticalGroup>
              )}
            </Container>
          </div>
        </>
      )}
    </div>
  );
}

export default GameController;
