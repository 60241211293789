import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import ModelAPI from "../../../services/modelApi";
import ModelAdjustmentsContent from "../model/ModelAdjustments/ModelAdjustmentsContent";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
  teams: API.GameConnection[];
  onAdjustmentMade: () => void;
}

const FacilitatorModelAdjustments: React.FC<Props> = ({
  eventId,
  selectedRound,
  configuration,
  teams,
  onAdjustmentMade,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const adjustmentsRef = useRef<ModelAPI.FacilitatorAdjustmentsResponse | null>(
    null,
  );

  const resultsRef = useRef<ModelAPI.RoundResultsResponse[] | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const [adjustments, results] = await Promise.all([
        ModelAPI.getAdjustments(eventId),
        ModelAPI.getModelTeamResultsAll(eventId, selectedRound),
      ]);
      adjustmentsRef.current = adjustments;
      resultsRef.current = results;
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, selectedRound]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdate = useCallback(
    async (
      type: keyof ModelAPI.FacilitatorAdjustmentsResponse,
      teamId: number,
      year: number,
      value: number,
    ) => {
      try {
        await ModelAPI.updateAdjustments(
          eventId,
          year - configuration.numberOfHistoricRounds + 1,
          { teamId, type, value },
        );

        onAdjustmentMade();

        if (adjustmentsRef.current) {
          adjustmentsRef.current[type][teamId][year] = value;
        }

        const updatedResults = await ModelAPI.getModelTeamResultsAll(
          eventId,
          selectedRound,
        );
        resultsRef.current = updatedResults;
      } catch (err) {
        console.error("Error updating adjustments:", err);
      }
    },
    [eventId, configuration, selectedRound, onAdjustmentMade],
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Banner
        type="error"
        active
        message={error.message || "An error occurred"}
      />
    );
  }

  if (!adjustmentsRef.current || !resultsRef.current) {
    return null;
  }

  return (
    <ModelAdjustmentsContent
      eventId={eventId}
      selectedRound={selectedRound}
      configuration={configuration}
      teams={teams}
      adjustmentData={adjustmentsRef.current}
      resultData={resultsRef.current}
      onUpdate={handleUpdate}
      onAdjustmentMade={onAdjustmentMade}
    />
  );
};

export default FacilitatorModelAdjustments;
