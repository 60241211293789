import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import GloInput, { DataProps } from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["pricing"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const historicalNims: Array<DataProps<DataType, "historicalNims">> = [
  {
    label: "Home Loans",
    field: "homeLoans",
    type: "percentage",
  },
  {
    label: "Business Lending",
    field: "businessLoans",
    type: "percentage",
  },
  {
    label: "Deposits",
    field: "deposits",
    type: "percentage",
  },
  {
    label: "Credit Cards",
    field: "creditCards",
    type: "percentage",
  },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "percentage",
  },
];

const forecastNims: Array<DataProps<DataType, "forecastNims">> = [
  {
    label: "Home Loans",
    field: "homeLoans",
    type: "percentage",
  },
  {
    label: "Business Lending",
    field: "businessLoans",
    type: "percentage",
  },
  {
    label: "Deposits",
    field: "deposits",
    type: "percentage",
  },
  {
    label: "Credit Cards",
    field: "creditCards",
    type: "percentage",
  },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "percentage",
  },
];

const Pricing: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <Group title="Historical NIMs">
        {historicalNims.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.historicalNims[field]}
            onChange={onChange("historicalNims", field)}
            min={-1}
            max={1}
            step={0.1}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Forecast NIMs">
        {forecastNims.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.forecastNims[field]}
            onChange={onChange("forecastNims", field)}
            min={-1}
            max={1}
            step={0.1}
            {...rest}
          />
        ))}
      </Group>
    </VerticalGroup>
  );
};
export default Pricing;
