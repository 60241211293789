import React, { useCallback } from "react";
import Modal from "../../../atoms/modal/Modal";
import ModalBody from "../../../molecules/modal-body/ModalBody";
import ResultsFontMutiplierDropdown from "./ResultsFontMultiplierDropdown";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../atoms/button/Button";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Clickable from "../../../atoms/clickable/Clickable";
import Icon from "../../../atoms/icon/Icon";
import { useResultsContext } from "../context/context";
import Container from "../../../atoms/page/Page";
import Grid from "../../../atoms/grid/Grid";
import PresentResultsRndDropdown from "../../game/PresentResultsRndDropdown";
import DebugInfoModal from "./DebugInfoModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RoundResultsPresenterSettingModal = ({ isOpen, onClose }: Props) => {
  const context = useResultsContext();

  const handlePresentationModeClick = useCallback(() => {
    context.enterPresentationMode();
  }, [context]);

  const handlePresentationModeExit = useCallback(() => {
    context.exitPresentationMode();
  }, [context]);

  const handleRefresh = useCallback(() => {
    context.updateRefreshPresentationKey(context.refreshPresentationKey + 1);
  }, [context]);

  const handleRoundChange = useCallback(
    (round: number) => {
      context.updateRound({ round });
      context.updateRefreshPresentationKey(context.refreshPresentationKey + 1);
    },
    [context],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalBody
        type="standard"
        title="Presentation Settings"
        description="Update the presentation settings"
      >
        <VerticalGroup spaceBetweenElements={4} className="mt-4">
          <Container fit>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spread block>
                <div> Rnd</div>
                <PresentResultsRndDropdown
                  currentRound={context.roundNumber}
                  maxRound={6}
                  onChange={handleRoundChange}
                />
              </InlineGroup>
              <InlineGroup verticalCenter spread block>
                <div> Font size multiplier</div>
                <ResultsFontMutiplierDropdown />
              </InlineGroup>
              <Grid>
                <Clickable
                  onClick={() =>
                    context.updateCastState({ cast: !context.cast })
                  }
                >
                  <VerticalGroup center verticalCenter>
                    <Icon
                      type="cast"
                      colour={context.cast ? "secondaryBlue" : undefined}
                      size={10}
                      tip={{ content: "Cast to participants", id: "cast" }}
                    />
                    Cast to participants
                  </VerticalGroup>
                </Clickable>
                <Clickable onClick={handlePresentationModeClick}>
                  <VerticalGroup center verticalCenter>
                    <Icon color="secondaryBlue" size={10} type="fullScreen" />
                    Full Screen
                  </VerticalGroup>
                </Clickable>
                <Clickable onClick={handlePresentationModeExit}>
                  <VerticalGroup center verticalCenter>
                    <Icon color="secondaryBlue" size={10} type="Minimize" />
                    Close Full Screen
                  </VerticalGroup>
                </Clickable>
                <Clickable onClick={() => context.updatePageIndex(0)}>
                  <VerticalGroup center verticalCenter>
                    <Icon size={10} type="arrowCounterClockwise" />
                    Back To Slide 1
                  </VerticalGroup>
                </Clickable>
                <Clickable onClick={handleRefresh}>
                  <VerticalGroup center verticalCenter>
                    <Icon size={10} type="refresh" />
                    Refresh Window
                  </VerticalGroup>
                </Clickable>
                <DebugInfoModal />
              </Grid>
            </VerticalGroup>
          </Container>
          <InlineGroup right block>
            <Button
              data-test="modal-confimation-confirm"
              onClick={onClose}
              large
            >
              Close
            </Button>
          </InlineGroup>
        </VerticalGroup>
      </ModalBody>
    </Modal>
  );
};

export default RoundResultsPresenterSettingModal;
