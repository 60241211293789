import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Actions, ResultsState } from "./state";
import { NotImplemented } from "../../../../constants/functions";
import { FontSizeMultiplier } from "../results-text/ResultsText";

interface Context {
  eventId: ResultsState["eventId"];
  roundNumber: ResultsState["roundNumber"];
  pages: ResultsState["pages"];
  hideTeamPositions: ResultsState["hideTeamPositions"];
  hidePagination: ResultsState["hidePagination"];
  hideHeader: ResultsState["hideHeader"];
  showRemoteCloseButton: ResultsState["showRemoteCloseButton"];
  noAnimations: ResultsState["noAnimations"];
  noAudio: ResultsState["noAudio"];
  noVideo: ResultsState["noVideo"];
  allowCustomTemplateEdits: ResultsState["allowCustomTemplateEdits"];
  renderKey: ResultsState["renderKey"];
  refreshPresentationKey: ResultsState["refreshPresentationKey"];
  pageIndex: ResultsState["pageIndex"];
  clicks: ResultsState["clicks"];
  version: ResultsState["version"];
  calcVersion: ResultsState["calcVersion"];
  syncState: ResultsState["syncState"];
  cast: ResultsState["cast"];
  ignoreKeyPress: ResultsState["ignoreKeyPress"];
  ignoreFontMultiplier: ResultsState["ignoreFontMultiplier"];
  allowPresentationMode: ResultsState["allowPresentationMode"];
  fontMultiplier: ResultsState["fontMultiplier"];
  readSyncState: ResultsState["readSyncState"];
  onLayoutUpdate?: ResultsState["onLayoutUpdate"];
  onCustomTemplateEdit: ResultsState["onCustomTemplateEdit"];
  updatePages: (payload: API.ReportingTemplateLayoutResponse[]) => void;
  updateHideTeamPositions: (payload: boolean) => void;
  updateHidePagination: (payload: boolean) => void;
  updateHideHeader: (payload: boolean) => void;
  updateNoAnimations: (payload: boolean) => void;
  UpdateNoAudio: (payload: boolean) => void;
  UpdateNoVideo: (payload: boolean) => void;
  updateRenderKey: (payload: number) => void;
  updateRefreshPresentationKey: (payload: number) => void;
  updatePageIndex: (payload: number) => void;
  updateClicks: (payload: number) => void;
  updateAllowCustomTemplateEdits: (payload: boolean) => void;
  updateOnCustomTemplateEdit: (
    payload: ResultsState["onCustomTemplateEdit"],
  ) => void;
  updateAllowPresentationMode: (payload: boolean) => void;
  enterPresentationMode: () => void;
  exitPresentationMode: () => void;
  updateFontMultiplier: (payload: FontSizeMultiplier) => void;
  goNextPage: () => void;
  goPreviousPage: () => void;
  updatePageAndClicks: (payload: {
    pageIndex: number;
    clicks: number;
    version: number;
  }) => void;
  updatePageClicksAndRoundNumber: (payload: {
    pageIndex: number;
    clicks: number;
    round: number;
    version: number;
    calcVersion: number;
  }) => void;
  addClick: () => void;
  updateCastState: (payload: { cast: boolean; version?: number }) => void;
  updateRound: (payload: { round: number; version?: number }) => void;
}

export const ResultsContext = createContext<Context>({
  eventId: "",
  roundNumber: 0,
  hideTeamPositions: false,
  hidePagination: false,
  hideHeader: false,
  showRemoteCloseButton: false,
  noAnimations: false,
  noAudio: false,
  noVideo: false,
  allowCustomTemplateEdits: false,
  renderKey: 0,
  refreshPresentationKey: 0,
  pageIndex: 0,
  clicks: 0,
  version: 0,
  calcVersion: 0,
  cast: false,
  syncState: false,
  pages: [],
  allowPresentationMode: false,
  fontMultiplier: 1,
  readSyncState: false,
  ignoreKeyPress: false,
  ignoreFontMultiplier: false,
  onCustomTemplateEdit: NotImplemented,
  updatePages: NotImplemented,
  updateHideTeamPositions: NotImplemented,
  updateHidePagination: NotImplemented,
  updateHideHeader: NotImplemented,
  updateNoAnimations: NotImplemented,
  UpdateNoAudio: NotImplemented,
  UpdateNoVideo: NotImplemented,
  updateRenderKey: NotImplemented,
  updateRefreshPresentationKey: NotImplemented,
  updatePageIndex: NotImplemented,
  updateClicks: NotImplemented,
  updateAllowCustomTemplateEdits: NotImplemented,
  updateOnCustomTemplateEdit: NotImplemented,
  updateAllowPresentationMode: NotImplemented,
  enterPresentationMode: NotImplemented,
  exitPresentationMode: NotImplemented,
  updateFontMultiplier: NotImplemented,
  goNextPage: NotImplemented,
  goPreviousPage: NotImplemented,
  updatePageAndClicks: NotImplemented,
  updatePageClicksAndRoundNumber: NotImplemented,
  addClick: NotImplemented,
  updateCastState: NotImplemented,
  updateRound: NotImplemented,
});

export const useResultsContextValue = (
  state: ResultsState,
  dispatch: Dispatch<Actions>,
): Context => {
  const updatePages = useCallback(
    (payload: API.ReportingTemplateLayoutResponse[]) => {
      dispatch({
        type: "UpdatePages",
        payload,
      });
    },
    [dispatch],
  );

  const updateHideTeamPositions = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateHideTeamPositions",
        payload,
      });
    },
    [dispatch],
  );

  const updateHidePagination = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateHidePagination",
        payload,
      });
    },
    [dispatch],
  );

  const updateHideHeader = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateHideHeader",
        payload,
      });
    },
    [dispatch],
  );

  const updateNoAnimations = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateNoAnimations",
        payload,
      });
    },
    [dispatch],
  );

  const UpdateNoAudio = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateNoAudio",
        payload,
      });
    },
    [dispatch],
  );

  const UpdateNoVideo = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateNoVideo",
        payload,
      });
    },
    [dispatch],
  );

  const updateRenderKey = useCallback(
    (payload: number) => {
      dispatch({
        type: "UpdateRenderKey",
        payload,
      });
    },
    [dispatch],
  );

  const updateRefreshPresentationKey = useCallback(
    (payload: number) => {
      dispatch({
        type: "UpdateRefreshPresentationKey",
        payload,
      });
    },
    [dispatch],
  );

  const updatePageIndex = useCallback(
    (payload: number) => {
      dispatch({
        type: "UpdatePageIndex",
        payload,
      });
    },
    [dispatch],
  );

  const updateClicks = useCallback(
    (payload: number) => {
      dispatch({
        type: "updateClicks",
        payload,
      });
    },
    [dispatch],
  );

  const updateAllowCustomTemplateEdits = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateAllowCustomTemplateEdits",
        payload,
      });
    },
    [dispatch],
  );

  const updateOnCustomTemplateEdit = useCallback(
    (payload: ResultsState["onCustomTemplateEdit"]) => {
      dispatch({
        type: "UpdateOnCustomTemplateEdit",
        payload,
      });
    },
    [dispatch],
  );

  const updateAllowPresentationMode = useCallback(
    (payload: boolean) => {
      dispatch({
        type: "UpdateAllowPresentationMode",
        payload,
      });
    },
    [dispatch],
  );

  const enterPresentationMode = useCallback(() => {
    updateRenderKey(state.renderKey + 1);
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
      // @ts-expect-error this is there for safari
    } else if (document.documentElement.webkitRequestFullscreen) {
      // @ts-expect-error this is there for safari
      document.documentElement.webkitRequestFullscreen();
      // @ts-expect-error this is there for IE11
    } else if (document.documentElement.msRequestFullscreen) {
      // @ts-expect-error this is there forIE11
      document.documentElement.msRequestFullscreen();
    }
  }, [state.renderKey, updateRenderKey]);

  const exitPresentationMode = useCallback(() => {
    updateRenderKey(state.renderKey + 1);
  }, [state.renderKey, updateRenderKey]);

  const updateFontMultiplier = useCallback(
    (val: FontSizeMultiplier) => {
      dispatch({
        type: "UpdateFontMultiplier",
        payload: { fontMultiplier: val },
      });
    },
    [dispatch],
  );

  const goNextPage = useCallback(() => {
    dispatch({
      type: "GoNextPage",
    });
  }, [dispatch]);

  const goPreviousPage = useCallback(() => {
    dispatch({
      type: "GoPreviousPage",
    });
  }, [dispatch]);

  const updatePageAndClicks = useCallback(
    (payload: { pageIndex: number; clicks: number; version: number }) => {
      dispatch({
        type: "UpdatePageAndClicks",
        payload,
      });
    },
    [dispatch],
  );
  const updatePageClicksAndRoundNumber = useCallback(
    (payload: {
      pageIndex: number;
      clicks: number;
      round: number;
      version: number;
      calcVersion: number;
    }) => {
      dispatch({
        type: "UpdatePageClicksAndRoundNumber",
        payload,
      });
    },
    [dispatch],
  );

  const addClick = useCallback(() => {
    dispatch({
      type: "AddClick",
    });
  }, [dispatch]);

  const updateCastState = useCallback(
    (payload: { cast: boolean; version?: number }) => {
      dispatch({
        type: "UpdateCastState",
        payload,
      });
    },
    [dispatch],
  );
  const updateRound = useCallback(
    (payload: { round: number; version?: number }) => {
      dispatch({
        type: "UpdateRound",
        payload,
      });
    },
    [dispatch],
  );

  const context = useMemo(
    () => ({
      eventId: state.eventId,
      roundNumber: state.roundNumber,
      pages: state.pages,
      hideTeamPositions: state.hideTeamPositions,
      hidePagination: state.hidePagination,
      hideHeader: state.hideHeader,
      showRemoteCloseButton: state.showRemoteCloseButton,
      noAnimations: state.noAnimations,
      noAudio: state.noAudio,
      noVideo: state.noVideo,
      allowCustomTemplateEdits: state.allowCustomTemplateEdits,
      renderKey: state.renderKey,
      refreshPresentationKey: state.refreshPresentationKey,
      pageIndex: state.pageIndex,
      clicks: state.clicks,
      version: state.version,
      calcVersion: state.calcVersion,
      cast: state.cast,
      syncState: state.syncState,
      ignoreKeyPress: state.ignoreKeyPress,
      ignoreFontMultiplier: state.ignoreFontMultiplier,
      allowPresentationMode: state.allowPresentationMode,
      fontMultiplier: state.fontMultiplier,
      readSyncState: state.readSyncState,
      onCustomTemplateEdit: state.onCustomTemplateEdit,
      onLayoutUpdate: state.onLayoutUpdate,
      updatePages,
      updateHideTeamPositions,
      updateHideHeader,
      updateHidePagination,
      updateNoAnimations,
      UpdateNoAudio,
      UpdateNoVideo,
      updateAllowCustomTemplateEdits,
      updateOnCustomTemplateEdit,
      updateRenderKey,
      updateRefreshPresentationKey,
      updatePageIndex,
      updateClicks,
      updateAllowPresentationMode,
      enterPresentationMode,
      exitPresentationMode,
      updateFontMultiplier,
      goNextPage,
      goPreviousPage,
      updatePageAndClicks,
      updatePageClicksAndRoundNumber,
      addClick,
      updateCastState,
      updateRound,
    }),
    [
      state.eventId,
      state.roundNumber,
      state.pages,
      state.hideTeamPositions,
      state.hidePagination,
      state.hideHeader,
      state.showRemoteCloseButton,
      state.noAnimations,
      state.noAudio,
      state.noVideo,
      state.allowCustomTemplateEdits,
      state.onLayoutUpdate,
      state.renderKey,
      state.refreshPresentationKey,
      state.pageIndex,
      state.clicks,
      state.version,
      state.calcVersion,
      state.cast,
      state.syncState,
      state.ignoreKeyPress,
      state.ignoreFontMultiplier,
      state.onCustomTemplateEdit,
      state.allowPresentationMode,
      state.fontMultiplier,
      state.readSyncState,
      updatePages,
      updateHideTeamPositions,
      updateHideHeader,
      updateHidePagination,
      updateNoAnimations,
      UpdateNoAudio,
      UpdateNoVideo,
      updateAllowCustomTemplateEdits,
      updateOnCustomTemplateEdit,
      updateRenderKey,
      updateRefreshPresentationKey,
      updatePageIndex,
      updateClicks,
      updateAllowPresentationMode,
      enterPresentationMode,
      exitPresentationMode,
      updateFontMultiplier,
      goNextPage,
      goPreviousPage,
      updatePageAndClicks,
      updatePageClicksAndRoundNumber,
      addClick,
      updateCastState,
      updateRound,
    ],
  );

  return context;
};

export const useResultsContext = () => {
  return useContext(ResultsContext);
};
