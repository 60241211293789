import React, { useState, useCallback, useMemo } from "react";
import Button from "../../../atoms/button/Button";
import Clickable from "../../../atoms/clickable/Clickable";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import Container from "../../../atoms/page/Page";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { useResultsContext } from "../context/context";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import Text from "../../../atoms/text/Text";
import "./DebugInfoModal.scss";

interface InfoSectionProps {
  title: string;
  data: Record<string, any>;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, data }) => (
  <VerticalGroup className="debug-info-section">
    <Text className="debug-info-section-title" bold size="lg">
      {title}:
    </Text>
    {Object.entries(data).map(([key, value]) => (
      <div key={key} className="debug-info-item">
        <span className="debug-info-item-key">{key}:</span> {value}
      </div>
    ))}
  </VerticalGroup>
);

const DebugInfoModal: React.FC = () => {
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const context = useResultsContext();

  const handleDebugInfoClick = useCallback(() => {
    setIsDebugModalOpen(true);
  }, []);

  const getDebugInfo = useCallback(() => {
    return {
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
        colorDepth: window.screen.colorDepth,
        pixelDepth: window.screen.pixelDepth,
        orientation: window.screen.orientation
          ? window.screen.orientation.type
          : "N/A",
      },
      window: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
      },
      other: {
        devicePixelRatio: window.devicePixelRatio,
        zoomLevel: Math.round((window.outerWidth / window.innerWidth) * 100),
        userAgent: navigator.userAgent,
        cast:
          context.cast !== undefined
            ? context.cast.toString()
            : "Not available",
      },
    };
  }, [context.cast]);

  const debugInfo = useMemo(() => getDebugInfo(), [getDebugInfo]);

  const handleCloseModal = useCallback(() => {
    setIsDebugModalOpen(false);
  }, []);

  return (
    <>
      <Clickable onClick={handleDebugInfoClick}>
        <VerticalGroup center verticalCenter>
          <Icon size={10} type="screenInfo" />
          Debug Info
        </VerticalGroup>
      </Clickable>
      <SimpleModal
        isOpen={isDebugModalOpen}
        onClose={handleCloseModal}
        size="medium"
      >
        <VerticalGroup spaceBetweenElements={4}>
          <Text bold size="xl">
            Debug Info
          </Text>
          <Container fit>
            <div className="debug-info-modal">
              <InlineGroup>
                <div style={{ flex: 1 }}>
                  <InfoSection title="Screen" data={debugInfo.screen} />
                </div>
                <div className="debug-vertical-divider" />
                <InlineGroup style={{ flex: 1 }}>
                  <InfoSection title="Window" data={debugInfo.window} />
                </InlineGroup>
              </InlineGroup>
              <div className="debug-horizontal-divider" />
              <InfoSection title="Other" data={debugInfo.other} />
            </div>
          </Container>
          <InlineGroup block right>
            <Button onClick={handleCloseModal}>Close</Button>
          </InlineGroup>
        </VerticalGroup>
      </SimpleModal>
    </>
  );
};

export default DebugInfoModal;
