import React from "react";
import ResultsText from "../results-text/ResultsText";
import DOMPurify from "dompurify";

interface Props {
  html: string;
  index?: number;
}

const ParseHtmlIntoResultsTest: React.FC<Props> = ({ html, index = 0 }) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(DOMPurify.sanitize(html), "text/html");
  const elements = doc.body.childNodes;

  const parsedContent = Array.from(elements).map((element, i) => {
    const key = `${index}-${i}`;

    if (element.nodeType === Node.TEXT_NODE) {
      return (
        <ResultsText key={key} size="sm">
          {element.textContent}
        </ResultsText>
      );
    } else if (element.nodeType === Node.ELEMENT_NODE) {
      const tagName = (element as Element).tagName.toLowerCase();
      let size = "sm";
      let alignRight = false;
      let alignCenter = false;
      let alignLeft = true;

      if (tagName === "h1") {
        size = "2xl";
      } else if (tagName === "h2") {
        size = "xl";
      } else if (tagName === "h3") {
        size = "lg";
      } else if (tagName === "h4") {
        size = "md";
      } else if (tagName === "p") {
        size = "sm";
      }

      const styleAttr = (element as Element).getAttribute("style");
      if (styleAttr) {
        const styles = styleAttr.split(";").map((s) => s.trim());
        const textAlignStyle = styles.find((s) => s.startsWith("text-align:"));
        if (textAlignStyle) {
          const textAlign = textAlignStyle.split(":")[1].trim();
          if (textAlign === "right") {
            alignRight = true;
            alignLeft = false;
          } else if (textAlign === "center") {
            alignCenter = true;
            alignLeft = false;
          }
        }
      }

      const sanitizedHtml = DOMPurify.sanitize((element as Element).innerHTML);

      return (
        <ResultsText
          key={key}
          size={size}
          right={alignRight}
          center={alignCenter}
          left={alignLeft}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </ResultsText>
      );
    }

    return null;
  });

  return <>{parsedContent}</>;
};

export default ParseHtmlIntoResultsTest;
