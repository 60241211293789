import React, { useCallback, useState } from "react";
import ExcoDecisionSelect from "../../simulations/steps/excodecision/ExcoDecisionSelect";
import { useExcoConfig } from "../../../../hooks/useExcoConfig";
import api from "../../../../services/api";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function ExcoDecisions() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);
  const { data: excoDecisions, inProgress, error } = useExcoConfig();

  const handleExcoDecisionsUpdate = useCallback(
    async (excoId: string, data: Partial<API.ExcoConfigResponse>) => {
      const response = await api.updateExcoConfig(excoId, data);
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const handleExcoDecisionsOptionUpdate = useCallback(
    async (
      excoId: string,
      optionId: string,
      data: Partial<API.ExcoOptionConfig>,
    ) => {
      const response = await api.updateExcoOptionConfig(excoId, optionId, data);
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  return (
    <>
      <h3>Executive Team Decision Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {excoDecisions && (
        <ExcoDecisionSelect
          onUpdateContent={handleExcoDecisionsUpdate}
          onUpdateOption={handleExcoDecisionsOptionUpdate}
          excoDecisions={excoDecisions}
        />
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Executive Decisions"
          />
        )}
    </>
  );
}

export default ExcoDecisions;
