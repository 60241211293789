import React from "react";
import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import { getLabelFromValue as getTermFundingLabel } from "./TermFundingDropdown";
import { getLabelFromValue as getCapitalManagementLabel } from "./CapitalManagementDropdown";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data?: API.SimulationResponse;
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  const moduleSetup = data?.moduleSetup;
  const projectsSetup = data?.specialProjectsSetup;
  const summaryMetrics = data?.summaryMetrics;

  const openingValuesMapping: Record<string, { value: any; label: string }> = {
    NPS: { value: moduleSetup?.npsOpeningValue, label: "NPS" },
    Complaints: {
      value: moduleSetup?.complaintsOpeningValue,
      label: "Complaints",
    },
    RiskIncidents: {
      value: moduleSetup?.riskIncidentsOpeningValue,
      label: "Risk Incidents",
    },
    EmployeeEngagement: {
      value: moduleSetup?.employeeEngagementOpeningValue,
      label: "Employee Engagement",
    },
    Community: {
      value: moduleSetup?.communityTrustOpeningValue,
      label: "Community Trust",
    },
  };

  const renderOpeningValues = () => {
    if (!summaryMetrics && !data?.financialSummaryMetrics) return null;

    const allMetrics = [
      ...(summaryMetrics || []),
      ...(data?.financialSummaryMetrics || []),
    ];

    return Object.entries(openingValuesMapping).map(
      ([metricType, mappedMetric]) => {
        const metricExists = allMetrics.some(
          (metric) => metric.type === metricType,
        );

        if (metricExists) {
          let displayValue = mappedMetric.value;
          if (
            metricType === "EmployeeEngagement" ||
            metricType === "Community"
          ) {
            displayValue = `${displayValue}%`;
          }
          return (
            <DisplayField
              key={metricType}
              label={mappedMetric.label}
              value={displayValue}
            />
          );
        }
        return null;
      },
    );
  };

  return moduleSetup ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Economy and Other Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-module-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Module",
                  id: "edit-module",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <DisplayField label="Economy" value={moduleSetup.economy} />
        <DisplayField
          label="Term Funding"
          value={getTermFundingLabel(moduleSetup.termFunding)}
        />
        <DisplayField
          label="Capital Management"
          value={getCapitalManagementLabel(moduleSetup.capitalManagement)}
        />
        <DisplayField
          label="Operating Costs"
          value={moduleSetup.operatingCostsEnabled ? "Enabled" : "Disabled"}
        />
        <DisplayField
          label="Operational Risk Event"
          value={
            projectsSetup && projectsSetup.operationalRiskEnabled
              ? `Round #${projectsSetup.operationalRiskRound}`
              : "Disabled"
          }
        />
        <DisplayField
          label="Crime Syndicate Event"
          value={
            projectsSetup && projectsSetup?.crimeSyndicateEnabled
              ? `Round #${projectsSetup.crimeSyndicateRound}`
              : "Disabled"
          }
        />
        <h4>Opening Values</h4>
        {renderOpeningValues()}
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
