import React, { useCallback, useEffect, useState } from "react";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import { useReportingLayoutConfig } from "../../../../hooks/useReportingLayoutConfig";
import { useGenericPreviewReportingData } from "../../../../hooks/usePreviewReportingData";
import ReportingTemplateDetailsLayout from "../../reporting-templates/ReportingTemplateDetailsLayout";
import { DropResult } from "react-beautiful-dnd";

function ReportingLayouts() {
  const [selectedLayout, setSelectedLayout] =
    useState<API.ReportingTemplateLayoutResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [apiUpdating, setApiUpdating] = useState(false);
  const [layouts, setLayouts] = useState<API.ReportingTemplateLayoutResponse[]>(
    [],
  );
  const {
    data: apiLayouts,
    inProgress,
    error,
    refresh,
  } = useReportingLayoutConfig();

  const { data: previewData } = useGenericPreviewReportingData(null, 3);

  useEffect(() => {
    if (apiLayouts) {
      const mappedLayouts = apiLayouts.map((layout) => ({
        ...layout,
        enabled: true,
      }));
      setLayouts(mappedLayouts);
      if (
        !selectedLayout ||
        !apiLayouts.some((layout) => layout.id === selectedLayout.id)
      ) {
        setSelectedLayout(mappedLayouts[0]);
      }
    }
  }, [apiLayouts, selectedLayout]);

  const onLayoutUpdate = useCallback(
    async (
      layoutId: string,
      data: API.ReportingTemplateLayoutUpdateRequest,
    ) => {
      try {
        setApiUpdating(true);
        if (selectedLayout?.id === layoutId) {
          setSelectedLayout((sl) => ({
            ...sl!,
            ...data,
          }));
        }
        setLayouts((list) => {
          const result = Array.from(list);
          const index = result.findIndex((r) => r.id === layoutId);
          result[index] = { ...result[index], ...data };
          return result;
        });
        await api.updateReportingLayoutConfig(layoutId, data);
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [selectedLayout, refresh],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      let layoutsResult: API.ReportingTemplateLayoutResponse[] = [];

      setLayouts((list) => {
        const result = Array.from(list);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);
        layoutsResult = result;
        return result;
      });

      for (let i = 0; i < layoutsResult.length; i++) {
        const val = layoutsResult[i];
        if (val.config && val.config.type === "custom_image") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_left") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_right") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_video") {
          delete val.config.videoUrl;
        }
      }

      try {
        setApiUpdating(true);
        await api.patchBaseReportingLayouts(layoutsResult);
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [refresh],
  );

  return (
    <>
      {inProgress && !apiLayouts && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {apiError != null && (
        <Banner type="error" active={!!apiError} message={apiError} />
      )}
      {!!apiLayouts && !!previewData && !!selectedLayout && layouts?.length && (
        <ReportingTemplateDetailsLayout
          disabled={apiUpdating}
          droppableId={"masterConfig"}
          onDragEnd={onDragEnd}
          layouts={layouts}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          previewData={previewData}
          onLayoutUpdate={onLayoutUpdate}
          showEnabledControls={false}
        />
      )}
    </>
  );
}

export default ReportingLayouts;
