import React, { useCallback, useState } from "react";
import { useDealConfig } from "../../../../hooks/useDealConfig";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import DealRoomSelect from "../../simulations/steps/dealroom/DealRoomSelect";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function DealRoom() {
  const { data: deals, inProgress, error } = useDealConfig();
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const handleDealRoomUpdate = useCallback(
    async (dealId: string, data: Partial<API.DealConfigResponse>) => {
      const response = await api.updateDealConfig(dealId, data);
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  return (
    <>
      <h3>Deal Room Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {deals && (
        <DealRoomSelect onUpdateContent={handleDealRoomUpdate} deals={deals} />
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Deal Room"
          />
        )}
    </>
  );
}

export default DealRoom;
