import React, { useCallback, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import { SelectedView, Dropzone } from "../../../organisms/draganddrop";
import { financialSummaryMetricItems } from "../../simulations/steps/financial-summary/data";
import { DragDropContext } from "react-beautiful-dnd";
import { Noop } from "../../../../constants/functions";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function FinancialSummaryMetrics() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const getFinancialSummaryMetricConfig = useCallback(async () => {
    return await api.getFinancialSummaryMetricConfig();
  }, []);

  const {
    data: financialSummaryMetricConfig,
    inProgress,
    error,
  } = useMemoRequest(getFinancialSummaryMetricConfig);

  const handleFinancialSummaryMetricConfigUpdate = useCallback(
    async (
      metricConfigId: string,
      data: Partial<API.FinancialSummaryMetricConfig>,
    ) => {
      const response = await api.updateFinancialSummaryMetricConfig(
        metricConfigId,
        data,
      );
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const updateField = useCallback(
    (metricConfigId: string) => (field: string) => async (value: string) => {
      await handleFinancialSummaryMetricConfigUpdate(metricConfigId, {
        [field]: value,
      });
    },
    [handleFinancialSummaryMetricConfigUpdate],
  );

  const items = (financialSummaryMetricConfig ?? []).map((metricConfig) => {
    const item = financialSummaryMetricItems.find(
      (item) => item.type === metricConfig.type,
    );

    return {
      id: metricConfig.type,
      view: (
        <SelectedView
          label={metricConfig.name}
          icon={item?.icon}
          onUpdateField={updateField(metricConfig.id)}
        />
      ),
    };
  });

  return (
    <>
      <h3>Financial Summary Metric Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {financialSummaryMetricConfig && (
        <DragDropContext onDragEnd={Noop}>
          <Dropzone
            items={items}
            size={items.length}
            itemsPerPage={items.length}
            readOnly
          />
        </DragDropContext>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Financial Metrics"
          />
        )}
    </>
  );
}

export default FinancialSummaryMetrics;
