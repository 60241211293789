import React, { useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import LabelsByType from "../../simulations/steps/labels/LabelsByType";

const getLabels = async () => {
  return await api.getLabelsConfig();
};

const handleLabelUpdate = async (
  labelId: string,
  data: Partial<API.LabelConfigResponse>,
) => {
  await api.updateLabelConfig(labelId, data);
};

const LabelsScreen: React.FC = () => {
  const [tab, setTab] = useState<API.LabelTypes>("main_menu");

  const { data: labels, inProgress, error } = useMemoRequest(getLabels);

  const sortByArray = [
    "informationLabel",
    "summaryButton",
    "newsButton",
    "peerInsightsButton",
    "ourFinancialsButton",
    "helpManualButton",
    "decisionsLabel",
    "excoButton",
    "specialProjectsButton",
    "costsButton",
    "businessesButton",
    "treasuryButton",
    "dealRoomButton",
    "assessmentButton",
  ];

  return (
    <>
      <h3>Labels</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {labels && (
        <VerticalGroup full wide>
          <InlineGroup spaceBetweenElements={2} className="h-16 mt-2" block>
            <Ribbon
              first
              active={tab === "main_menu"}
              title="Main Menu"
              onClick={() => setTab("main_menu")}
              className="h-16"
            />
            <Ribbon
              last
              active={tab === "team"}
              title="Team"
              onClick={() => setTab("team")}
              className="h-16"
            />
          </InlineGroup>
          {tab === "main_menu" && (
            <LabelsByType
              onUpdateContent={handleLabelUpdate}
              type={tab}
              items={labels}
              sortBy={sortByArray}
            />
          )}
          {tab === "team" && (
            <LabelsByType
              onUpdateContent={handleLabelUpdate}
              type={tab}
              items={labels}
            />
          )}
        </VerticalGroup>
      )}
    </>
  );
};

export default LabelsScreen;
