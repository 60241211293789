import React, { useEffect, useMemo } from "react";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import SquareIconButton from "../../molecules/square-icon-button/SquareIconButton";
import openNewWindow from "../../../lib/openNewWindow";
import Clickable from "../../atoms/clickable/Clickable";
import Icon from "../../atoms/icon/Icon";
import ClickableText from "../../atoms/text/ClickableText";
import CopyToClipboard from "react-copy-to-clipboard";
import { Noop } from "../../../constants/functions";
import { capitalize } from "../../../services/utils";

interface Props {
  isOpen: boolean;
  eventId: string;
  onClose: () => void;
}

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
const duplicatedWord = isMac ? "mirrored" : "duplicated";

function PresentResultsModal({ isOpen, eventId, onClose }: Props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [copied, setCopied] = React.useState<string | null>(null);

  useEffect(() => {
    if (copied != null) {
      const timeout = setTimeout(() => {
        setCopied(null);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const steps = useMemo(
    () => [
      {
        id: "1",
        content: (
          <>
            <Text size="lg" bold center>
              Is your screen being {duplicatedWord} or extended?
            </Text>
            <InlineGroup spaceBetweenElements={16}>
              <div className="width-50-percent">
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text size="sm" lineHeight={6}>
                    When the screen being shown to the audience is an exact
                    mirror of what is on your device.
                  </Text>
                  <SquareIconButton
                    icon="clone"
                    label={capitalize(duplicatedWord)}
                    onClick={() => setActiveStep(1)}
                  />
                </VerticalGroup>
              </div>
              <div className="width-50-percent">
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text size="sm" lineHeight={6}>
                    When the screen being shown to the audience is different to
                    what is on your device.
                  </Text>
                  <SquareIconButton
                    icon="maximize"
                    label="Extended"
                    onClick={() => setActiveStep(2)}
                  />
                </VerticalGroup>
              </div>
            </InlineGroup>
          </>
        ),
      },
      {
        id: "2",
        content: (
          <>
            <InlineGroup verticalCenter spread block>
              <Clickable onClick={() => setActiveStep(0)}>
                <Icon type="leftChevron" noMargin />
              </Clickable>
              <Text size="lg" bold>
                {capitalize(duplicatedWord)} Screen
              </Text>
              <Icon type="leftChevron" noMargin colour="white" />
            </InlineGroup>

            <Text size="md" lineHeight={6}>
              When your screen is being {duplicatedWord} you will see the same
              content on your screen as the presentation screen. In this case we
              will open a single window with the presentation content. You can
              move between the slides using the up and down arrows, or your
              clicker.
            </Text>
            <InlineGroup spaceBetweenElements={16}>
              <VerticalGroup spaceBetweenElements={2} center>
                <SquareIconButton
                  icon="presentation"
                  label="Open Presentation"
                  onClick={() => {
                    openNewWindow(
                      `${window.location.origin}/results/games/${eventId}/presentation`,
                    );
                  }}
                />
                <CopyToClipboard
                  text={`${window.location.origin}/results/games/${eventId}/presentation`}
                  onCopy={() => setCopied("presentation")}
                >
                  <InlineGroup spaceBetweenElements={2} verticalCenter>
                    <ClickableText onClick={Noop}>Copy Link</ClickableText>
                    {copied === "presentation" && (
                      <Icon type="tick" colour="green" />
                    )}
                  </InlineGroup>
                </CopyToClipboard>
              </VerticalGroup>
            </InlineGroup>
          </>
        ),
      },
      {
        id: "3",
        content: (
          <>
            <InlineGroup verticalCenter spread block>
              <Clickable onClick={() => setActiveStep(0)}>
                <Icon type="leftChevron" noMargin />
              </Clickable>
              <Text size="lg" bold>
                Extended Screen
              </Text>
              <Icon type="leftChevron" noMargin colour="white" />
            </InlineGroup>
            <Text size="md" lineHeight={6}>
              When your screen is being extended you will see different content
              on your screen to the presentation screen. You will need to open 2
              windows, one for the Presentation and one for the Speaker
              Controls.
            </Text>
            <InlineGroup spaceBetweenElements={12}>
              <VerticalGroup
                className="width-50-percent"
                spaceBetweenElements={2}
                center
              >
                <VerticalGroup spaceBetweenElements={2} center>
                  <SquareIconButton
                    icon="presenter"
                    label="Open Speaker Controls"
                    onClick={() => {
                      openNewWindow(
                        `${window.location.origin}/results/games/${eventId}/presenter`,
                        {
                          left: 10,
                          top: 0,
                          width: 800,
                          height: 600,
                          windowName: "Speaker Controls",
                        },
                      );
                    }}
                  />
                  <CopyToClipboard
                    text={`${window.location.origin}/results/games/${eventId}/presenter`}
                    onCopy={() => setCopied("presenter")}
                  >
                    <InlineGroup spaceBetweenElements={2} verticalCenter>
                      <ClickableText onClick={Noop}>Copy Link</ClickableText>
                      {copied === "presenter" && (
                        <InlineGroup spaceBetweenElements={2} verticalCenter>
                          <Icon type="tick" colour="green" />
                          <Text>Link copied</Text>
                        </InlineGroup>
                      )}
                    </InlineGroup>
                  </CopyToClipboard>
                </VerticalGroup>
              </VerticalGroup>
              <VerticalGroup
                className="width-50-percent"
                spaceBetweenElements={2}
                center
              >
                <VerticalGroup spaceBetweenElements={2} center>
                  <SquareIconButton
                    icon="presentation"
                    label="Open Presentation &nbsp;"
                    onClick={() => {
                      openNewWindow(
                        `${window.location.origin}/results/games/${eventId}/presentation-reader`,
                        {
                          left: 50,
                          top: 50,
                          width: 800,
                          height: 600,
                          windowName: "Presentation",
                        },
                      );
                    }}
                  />
                  <CopyToClipboard
                    text={`${window.location.origin}/results/games/${eventId}/presentation-reader`}
                    onCopy={() => setCopied("presentation")}
                  >
                    <InlineGroup spaceBetweenElements={2} verticalCenter>
                      <ClickableText onClick={Noop}>Copy Link</ClickableText>
                      {copied === "presentation" && (
                        <InlineGroup spaceBetweenElements={2} verticalCenter>
                          <Icon type="tick" colour="green" />
                          <Text>Link copied</Text>
                        </InlineGroup>
                      )}
                    </InlineGroup>
                  </CopyToClipboard>
                </VerticalGroup>
              </VerticalGroup>
            </InlineGroup>
          </>
        ),
      },
    ],
    [copied, eventId],
  );

  return (
    <SimpleModal isOpen={isOpen} showCloseButton size="small" onClose={onClose}>
      <VerticalGroup spaceBetweenElements={8} wide center>
        {steps[activeStep].content}
      </VerticalGroup>
    </SimpleModal>
  );
}

export default PresentResultsModal;
