import React from "react";

import Container from "../../../../../atoms/page/Page";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import ParticipantForm from "./ParticipantForm";
import useIsMobile from "../../../../../../hooks/useIsMobile";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (dismissPopup: boolean) => void;
  event: API.EventResponse;
  participant: API.ParticipantResponse;
  onNextParticipant?: () => void;
  onPrevParticipant?: () => void;
  deleteParticipant?: (participant: API.ParticipantResponse) => void;
  onWelcomeEmailSent?: () => void;
}

const EditParticipantModal: React.FC<Props> = ({
  event,
  participant,
  isOpen,
  onClose,
  onComplete,
  onNextParticipant,
  onPrevParticipant,
  deleteParticipant,
  onWelcomeEmailSent,
}) => {
  const isMobile = useIsMobile();
  return (
    <SimpleModal
      size={isMobile ? "scale" : "large"}
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      data-test="edit-participant-modal"
    >
      <Container fit className="simulation-edit">
        <h2>Edit participant</h2>
        <ParticipantForm
          event={event}
          onAfterSubmit={onComplete}
          onCancel={onClose}
          participant={participant}
          onNextParticipant={onNextParticipant}
          onPrevParticipant={onPrevParticipant}
          deleteParticipant={deleteParticipant}
          onWelcomeEmailSent={onWelcomeEmailSent}
        />
      </Container>
    </SimpleModal>
  );
};

export default EditParticipantModal;
