import React, { useCallback, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { Column, SortingRule } from "react-table";
import SortableTable from "../../../../../organisms/sortable-table/Sortable-table";
import { sortParticipants } from "../../../../../../lib/participantSort";
import { ThemeSpacing } from "../../../../../../types/theme";

type FlexibleColumn<D extends object = object> = Column<D> & {
  show?: boolean;
  hidden?: boolean;
};

interface ParticipantsTableProps {
  participants: API.ParticipantResponse[];
  event: API.EventResponse;
  onEditParticipant: (participant: API.ParticipantResponse) => void;
  onDeleteParticipant: (participant: API.ParticipantResponse) => void;
  onSelectedRowsChange: (selectedRows: API.ParticipantResponse[]) => void;
  onShowError: (errorMsg: string) => void;
  stickyTop?: ThemeSpacing;
  columns: FlexibleColumn<API.ParticipantResponse>[];
  showCheckboxes?: boolean;
  defaultSortBy?: SortingRule<API.ParticipantResponse>[];
}

const ParticipantsTable: React.FC<ParticipantsTableProps> = ({
  participants,
  stickyTop,
  onEditParticipant,
  onSelectedRowsChange,
  columns,
  showCheckboxes,
  defaultSortBy,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onRowClick = useCallback(
    (
      event: { stopPropagation: () => void; preventDefault: () => void },
      row: { original: API.ParticipantResponse },
    ) => {
      event.stopPropagation();
      event.preventDefault();
      onEditParticipant(row.original);
      console.log("Edit participant:", row.original);
    },
    [onEditParticipant],
  );

  const processedColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      hidden: column.hidden ?? column.show === false,
    }));
  }, [columns]);

  return (
    <SortableTable
      stickyHeader
      stickyTop={stickyTop}
      clickable
      columns={processedColumns}
      data={sortParticipants(participants)}
      selectedRowsCallback={onSelectedRowsChange}
      onRowClick={onRowClick}
      showCheckboxes={showCheckboxes}
      defaultSortBy={defaultSortBy}
    />
  );
};

export default ParticipantsTable;
