import React, { useCallback, useContext, useEffect, useState } from "react";

import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import { EventContext } from "../../context";
import useForm from "../../../../../../hooks/useForm";
import StepContainer from "../../components/StepContainer";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EventOptions from "../../components/EventOptions";
import Card from "../../../../../atoms/card/Card";
import ParticipantsTable from "./ParticipantsSection";
import API from "../../../../../../services/api";
import { AsyncNoop } from "../../../../../../constants/functions";
import BuildSimulationModal from "./BuildSimulationModal";

const Participants: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [confirmationComplete, setConfirmationComplete] = useState<
  //   null | (() => Promise<void | false>)
  // >(null);

  const {
    config,
    updateConfig,
    assignOnSubmitFunction,
    assignOnSubmitNextFunction,
    assignOnSubmitPreviousFunction,
    updateFormStatus,
    refreshConfig,
    jumpToStepByKey,
  } = useContext(EventContext);
  const [
    {
      formData: { participants },
      error,
      inProgress,
      formUpdated,
    },
    { handleSubmit, resetFormData },
  ] = useForm(config);

  const refreshEvent = useCallback(async () => {
    const response = await refreshConfig();
    if (response) {
      resetFormData(response);
    }
  }, [refreshConfig, resetFormData]);

  const [setupGameError, setSetupGameError] = useState<Error | null>(null);

  const handleBuild = useCallback(
    async ({ useAws }: { useAws: boolean }) => {
      if (config) {
        setShowConfirmation(false);
        try {
          await API.setupGame(config?.id, { uploadInitialResults: useAws });
          updateConfig({
            ...config,
            isGameSetup: true,
          });
          jumpToStepByKey("run-simulation");
        } catch (err) {
          setSetupGameError(err);
          return false;
        }
      }
    },
    [config, jumpToStepByKey, updateConfig],
  );

  useEffect(() => {
    if (config?.isGameSetup === false) {
      assignOnSubmitNextFunction(async () => {
        setShowConfirmation(true);
        return false;
      });
      assignOnSubmitPreviousFunction(AsyncNoop);
    } else {
      assignOnSubmitFunction(AsyncNoop);
    }
  }, [
    assignOnSubmitFunction,
    assignOnSubmitNextFunction,
    assignOnSubmitPreviousFunction,
    config,
    config?.id,
    config?.isGameSetup,
    handleSubmit,
    updateConfig,
  ]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <StepContainer>
      {showConfirmation && (
        <BuildSimulationModal
          isOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onBuild={handleBuild}
        />
      )}

      <Banner
        type="error"
        active={!!error || !!setupGameError}
        message={error?.message || setupGameError?.message}
      />

      <div></div>
      <InlineGroup spread verticalCenter block>
        <h2>Participants</h2>
        <EventOptions />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <ParticipantsTable
            event={config!}
            participants={participants}
            refreshEvent={refreshEvent}
            data={config}
          />
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Participants;
