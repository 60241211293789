import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";

const Group = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <h3>{title}</h3>
      <VerticalGroup wide spaceBetweenElements={2} className="pt-2 pb-2">
        {children}
      </VerticalGroup>
    </>
  );
};

export default Group;
