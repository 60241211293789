import React, { useCallback, useState } from "react";
import { useSpecialProjects } from "../../../../hooks/useSpecialProjects";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import SpecialProjectsSelect from "../../simulations/steps/special-projects/SpecialProjectsSelect";
import api from "../../../../services/api";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function SpecialProjects() {
  const { data: projects, inProgress, error, refresh } = useSpecialProjects();
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const handleSpecialProjectsUpdate = useCallback(
    async (projectId: string, data: Partial<API.SpecialProject>) => {
      const response = await api.updateProjectConfig(projectId, data);
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const handleSpecialProjectsImpactUpdate = useCallback(
    async (
      projectId: string,
      impactId: string,
      data: Partial<API.SpecialProjectImpact>,
    ) => {
      const response = await api.updateProjectImpactConfig(
        projectId,
        impactId,
        data,
      );
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
      refresh();
    },
    [refresh],
  );

  return (
    <>
      <h3>Special Project Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {projects && (
        <VerticalGroup className="mt-4">
          <SpecialProjectsSelect
            projects={projects}
            onUpdateContent={handleSpecialProjectsUpdate}
            onUpdateImpact={handleSpecialProjectsImpactUpdate}
          />
        </VerticalGroup>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Special Projects"
          />
        )}
    </>
  );
}

export default SpecialProjects;
