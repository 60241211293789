import React, { useCallback, useEffect, useReducer, useState } from "react";
import { reducer } from "./context/state";
import { Noop } from "../../../constants/functions";
import modelApi from "../../../services/modelApi";
import { ResultsContext, useResultsContextValue } from "./context/context";
import { FontSizeMultiplier } from "./results-text/ResultsText";

interface Props {
  eventId?: string;
  roundNumber: number;
  data: ModelAPI.Reporting.ReportingResults;
  onlyThesePages: API.ReportingLayoutType[];
  startingLayout?: API.ReportingLayoutType;
  hidePagination?: boolean;
  hideHeader?: boolean;
  hideTeamPositions?: boolean;
  noAnimations?: boolean;
  showRemoteCloseButton?: boolean;
  noAudio?: boolean;
  noVideo?: boolean;
  allowCustomTemplateEdits?: boolean;
  onCustomTemplateEdit?: (
    val: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
  allowPresentationMode?: boolean;
  syncState?: boolean;
  readSyncState?: boolean;
  ignoreKeyPress?: boolean;
  ignoreFontMultiplier?: boolean;
  id?: string;
  children: React.ReactNode;
  onLayoutUpdate?: (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
  allowHiddenLayouts?: boolean;
  refreshPresentation?: (round: number) => void;
  handleRoundChange?: (round: number, calcVersion: number) => void;
}

const RoundResultsSyncer = ({
  id,
  eventId,
  roundNumber,
  data,
  onlyThesePages,
  hidePagination,
  hideHeader,
  hideTeamPositions,
  showRemoteCloseButton,
  noAnimations,
  noAudio,
  noVideo,
  startingLayout,
  allowCustomTemplateEdits,
  onCustomTemplateEdit,
  allowPresentationMode,
  syncState,
  readSyncState,
  ignoreKeyPress,
  ignoreFontMultiplier,
  children,
  onLayoutUpdate,
  allowHiddenLayouts,
  refreshPresentation,
  handleRoundChange,
}: Props) => {
  const [lastVersion, setLastVersion] = useState(0);

  const [state, dispatch] = useReducer(reducer, {
    eventId: eventId ?? "",
    roundNumber,
    hideTeamPositions: hideTeamPositions ?? false,
    showRemoteCloseButton: showRemoteCloseButton ?? false,
    noAnimations: noAnimations ?? false,
    noAudio: noAudio ?? false,
    noVideo: noVideo ?? false,
    allowCustomTemplateEdits: allowCustomTemplateEdits ?? false,
    onCustomTemplateEdit: onCustomTemplateEdit ?? Noop,
    renderKey: 1,
    refreshPresentationKey: 1,
    pageIndex: 0,
    clicks: 0,
    version: 0,
    calcVersion: 0,
    hidePagination: hidePagination ?? false,
    pages: [],
    hideHeader: hideHeader ?? false,
    allowPresentationMode: allowPresentationMode ?? false,
    fontMultiplier: 1,
    syncState: syncState ?? false,
    readSyncState: readSyncState ?? false,
    ignoreKeyPress: ignoreKeyPress ?? false,
    ignoreFontMultiplier: ignoreFontMultiplier ?? false,
    cast: false,
    onLayoutUpdate: onLayoutUpdate,
  });

  useEffect(() => {
    if (state.refreshPresentationKey > 0) {
      refreshPresentation?.(state.roundNumber);
    }
  }, [state.refreshPresentationKey, refreshPresentation, state.roundNumber]);

  useEffect(() => {
    if (!data) {
      dispatch({
        type: "UpdatePages",
        payload: [],
      });
    } else {
      dispatch({
        type: "UpdatePages",
        payload: data.layouts.filter(
          (page) =>
            !!page &&
            page.enabled &&
            (allowHiddenLayouts || !page.overrideDisabled) &&
            (!onlyThesePages.length || onlyThesePages.includes(page.type)),
        ),
      });
    }
  }, [data, onlyThesePages, allowHiddenLayouts]);

  useEffect(() => {
    if (startingLayout) {
      const index = state.pages.findIndex((l) => l.type === startingLayout);
      if (index > -1) {
        dispatch({
          type: "UpdatePageIndex",
          payload: index,
        });
      }
    }
  }, [state.pages, startingLayout]);

  useEffect(() => {
    if (state.readSyncState) {
      const interval = setInterval(() => {
        fetchStateAndUpdate();
      }, 1000);
      return () => clearInterval(interval);
    }
  });

  const fetchStateAndUpdate = useCallback(async () => {
    const serverState = await modelApi.getReportingPresentationState(
      state.eventId,
      { bypass: state.allowPresentationMode },
    );
    if (
      serverState.version !== state.version &&
      serverState.version > state.version
    ) {
      if (
        serverState.index !== state.pageIndex ||
        serverState.clicks !== state.clicks ||
        serverState.round !== state.roundNumber ||
        serverState.calcVersion !== state.calcVersion
      ) {
        if (
          handleRoundChange &&
          (serverState.round !== state.roundNumber ||
            serverState.calcVersion !== state.calcVersion)
        ) {
          handleRoundChange(serverState.round, serverState.calcVersion);
        }
        setLastVersion(serverState.version);
        dispatch({
          type: "UpdatePageClicksAndRoundNumber",
          payload: {
            pageIndex: serverState.index,
            clicks: serverState.clicks,
            round: serverState.round,
            version: serverState.version,
            calcVersion: serverState.calcVersion,
          },
        });
      } else if (serverState.fontMultiplier !== state.fontMultiplier) {
        setLastVersion(serverState.version);
        dispatch({
          type: "UpdateFontMultiplier",
          payload: {
            fontMultiplier: serverState.fontMultiplier as FontSizeMultiplier,
            version: serverState.version,
          },
        });
      } else if (serverState.cast !== state.cast) {
        dispatch({
          type: "UpdateCastState",
          payload: {
            cast: serverState.cast,
            version: serverState.version,
          },
        });
        setLastVersion(serverState.version);
      }
    }
  }, [
    state.eventId,
    state.roundNumber,
    state.allowPresentationMode,
    state.version,
    state.calcVersion,
    state.pageIndex,
    state.clicks,
    state.fontMultiplier,
    state.cast,
    handleRoundChange,
  ]);

  const updateAndSet = useCallback(async () => {
    setLastVersion(state.version);
    await modelApi.updateReportingPresentationState(state.eventId, {
      index: state.pageIndex,
      clicks: state.clicks,
      version: state.version,
      fontMultiplier: state.fontMultiplier,
      cast: state.cast,
      round: state.roundNumber,
    });
  }, [
    state.clicks,
    state.eventId,
    state.pageIndex,
    state.roundNumber,
    state.version,
    state.fontMultiplier,
    state.cast,
  ]);

  useEffect(() => {
    if (state.syncState) {
      if (state.version !== lastVersion) {
        updateAndSet();
      }
    }
  }, [state, lastVersion, updateAndSet, id]);

  const resultsContext = useResultsContextValue(state, dispatch);

  return (
    <ResultsContext.Provider value={resultsContext}>
      {children}
    </ResultsContext.Provider>
  );
};

export default RoundResultsSyncer;
