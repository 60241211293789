import React, { useCallback, useRef, useState, MouseEvent } from "react";
import Card from "../../atoms/card/Card";
import Clickable from "../../atoms/clickable/Clickable";
import Icon from "../../atoms/icon/Icon";

import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import EditableText, { Handles } from "../../atoms/text/EditableText";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import DisconnectCEODevice from "./DisconnectCEODevice";
import { formatTeamName } from "./utils/getTeamName";
import API from "../../../services/api";
import ErrorModal from "../../organisms/standard-modal/ErrorModal";
import useIsMobile from "../../../hooks/useIsMobile";
import Button from "../../atoms/button/Button";

interface Props {
  gameId: string;
  teams: API.GameConnection[];
  event: API.EventResponse;
  handleLoginClick: (teamId: number) => void;
}

interface UIState {
  disconnectModalOpen: boolean;
  teamSelected: API.GameConnection | null;
}

const TeamItem: React.FC<{
  team: API.GameConnection;
  teams: API.GameConnection[];
  setUiState: any;
  event: API.EventResponse;
  handleLoginClick: (teamId: number) => void;
}> = ({ team, setUiState, event, handleLoginClick }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [originalTeamName, setOriginalTeamName] = useState(team.name);
  const nameEditorRef = useRef<Handles>(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const teamHasMaster = team.devices.some((d) => d.isMaster);
  const isMobile = useIsMobile();

  const onEditTeamIconClicked = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    if (nameEditorRef.current) {
      nameEditorRef.current.setIsEditing(true);
    }
  }, []);

  const onTeamNameUpdated = useCallback(
    (newTeamName: string) => {
      API.updateTeamInfo(event.id, team.teamId, {
        name: newTeamName,
        strategy: team.strategy || "",
      })
        .then(() => {
          setOriginalTeamName(newTeamName);
        })
        .catch((error) => {
          setErrorModalOpen(true);
          nameEditorRef.current?.reset();
          nameEditorRef.current?.setIsEditing(false);
          if (error.response && error.response.data) {
            console.log("dont know");
          } else {
            setErrorMessage(`${error}`);
          }
        });
    },
    [event.id, team.strategy, team.teamId],
  );

  return (
    <InlineGroup
      verticalCenter
      block
      evenWidthChildren
      key={`team-${team.teamId}`}
    >
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
        title="Error updating team name"
        description={
          errorMessage
            ? errorMessage
            : "Could not update the team name. Please try again."
        }
      />
      <InlineGroup verticalCenter spaceBetweenElements={isMobile ? 4 : 2}>
        <Clickable
          disabled={!teamHasMaster}
          centered
          onClick={(event) => {
            event.stopPropagation();
            setUiState({ disconnectModalOpen: true, teamSelected: team });
          }}
        >
          <Icon
            size={isMobile ? 6 : 4}
            type="disconnect"
            colour={teamHasMaster ? "danger" : "grey1"}
            noMargin
            tip={{
              content: (
                <>
                  <p>This will disconnect the CEO from the simulation</p>
                </>
              ),
              id: `${team.teamId}-disconnect`,
            }}
          />
        </Clickable>
        {isMobile ? (
          <InlineGroup block spread>
            <EditableText
              size="md"
              type="text"
              ref={nameEditorRef}
              value={originalTeamName}
              formatter={formatTeamName(team)}
              onChange={onTeamNameUpdated}
            />
            <InlineGroup style={{ width: "65%" }} spread verticalCenter>
              <Clickable centered onClick={onEditTeamIconClicked}>
                <Icon
                  size={6}
                  type="edit"
                  noMargin
                  tip={{
                    content: (
                      <>
                        <p>Edit team name</p>
                      </>
                    ),
                    id: `${team}-edit-team-name`,
                  }}
                />
              </Clickable>

              <Button
                outline
                wide
                onClick={() => handleLoginClick(team.teamId)}
              >
                <Text>Login</Text>
              </Button>
            </InlineGroup>
          </InlineGroup>
        ) : (
          <>
            <EditableText
              size="sm"
              type="text"
              ref={nameEditorRef}
              value={originalTeamName}
              formatter={formatTeamName(team)}
              onChange={onTeamNameUpdated}
            />
            <Clickable centered onClick={onEditTeamIconClicked}>
              <Icon
                size={4}
                type="edit"
                noMargin
                tip={{
                  content: (
                    <>
                      <p>Edit team name</p>
                    </>
                  ),
                  id: `${team}-edit-team-name`,
                }}
              />
            </Clickable>
          </>
        )}
      </InlineGroup>
      {!isMobile && (
        <InlineGroup evenWidthChildren verticalCenter>
          <Button outline wide onClick={() => handleLoginClick(team.teamId)}>
            <Text>Login</Text>
          </Button>

          <Icon
            noMargin
            type="status"
            colour={team.submitted ? "lightgreen" : "grey1"}
            tip={{
              content: (
                <>
                  <p>
                    The “Decisions” status indicates whether <br />
                    this team has submitted decisions.
                  </p>
                </>
              ),
              id: `${team.teamId}-status`,
            }}
          />
        </InlineGroup>
      )}
    </InlineGroup>
  );
};

function GameConnections({ teams, gameId, event, handleLoginClick }: Props) {
  const [{ disconnectModalOpen, teamSelected }, setUiState] = useState<UIState>(
    { disconnectModalOpen: false, teamSelected: null },
  );
  const isMobile = useIsMobile();

  const onDisconnectDeviceComplete = useCallback(() => {
    setUiState((prevState) => ({
      ...prevState,
      disconnectModalOpen: false,
      teamSelected: null,
    }));
  }, []);

  return (
    <Card wide flex>
      {disconnectModalOpen && !!teamSelected && (
        <DisconnectCEODevice
          gameId={gameId}
          isOpen={disconnectModalOpen}
          onClose={() =>
            setUiState((prevState) => ({
              ...prevState,
              disconnectModalOpen: false,
              teamSelected: null,
            }))
          }
          onComplete={onDisconnectDeviceComplete}
          team={teamSelected}
        />
      )}
      {!isMobile && (
        <InlineGroup block evenWidthChildren verticalCenter>
          <Text bold size="sm">
            Teams
          </Text>
          <InlineGroup evenWidthChildren>
            <Text bold size="sm" center>
              Login
            </Text>
            <Text bold size="sm" center>
              Decisions
            </Text>
          </InlineGroup>
        </InlineGroup>
      )}
      <VerticalGroup className="mt-6" spaceBetweenElements={6}>
        {teams.map((team) => {
          return (
            <TeamItem
              setUiState={setUiState}
              team={team}
              teams={teams}
              key={team.teamId}
              event={event}
              handleLoginClick={handleLoginClick}
            />
          );
        })}
      </VerticalGroup>
    </Card>
  );
}

export default GameConnections;
