import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsBarChart from "../charts/ResultsBarChart";
import {
  percentage,
  round,
  thousandSeparator,
} from "../../model/utils/formatters";
import ChartCard from "../components/ChartCard";
import { getYAxisDataV2 } from "../charts/yaxis";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { createBarChartTopLabel } from "../charts/BarChartTopLabel";
import { createBarChartMiddleLabel } from "../charts/BarChartMiddleLabel";
import { createLegend } from "../charts/BarChartLegend";
import { useResultsContext } from "../context/context";

interface Props {
  currencyDenomination: string;
  teams: TeamsWithColours[];
  npatRoe: ModelAPI.Reporting.NpatAndRoe[];
}

const RoundResultsNPATRoe: React.FC<Props> = ({
  currencyDenomination,
  teams,
  npatRoe,
}) => {
  const data = npatRoe.map((teamValue) => {
    const team = teams.find((t) => t.teamId === teamValue.teamId);
    if (team == null) {
      throw new Error("Team should exist");
    }
    const result: any = {
      name: team.teamName,
      teamId: teamValue.teamId,
      value: teamValue.npat,
      fill: team.colour,
      formatter: (val: number, params: any) => {
        if (params?.position === "top") {
          return thousandSeparator(round(teamValue.npat, 0));
        } else if (params?.position === "middle") {
          return percentage(teamValue.roe);
        }
        return "";
      },
      topLabel: createBarChartTopLabel((val: number) =>
        thousandSeparator(round(val, 0)),
      ),
      content: createBarChartMiddleLabel(
        (val: number) => percentage(val, 1),
        teams,
      ),
      hasMiddleLabel: true,
      middleLabelValue: teamValue.roe,
      legend: createLegend(teams),
    };
    for (const team of teams) {
      if (team.teamId !== teamValue.teamId) {
        result[`team${team.teamId}`] = null;
      }
    }
    return result;
  });

  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1: true,
    min: 0,
    data: [{ name: "fake0", value: 0 }].concat(
      npatRoe.map((np) => ({ name: "dddd", value: np.npat })),
    ),
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);
  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard
          heading="NPAT &amp; ROE"
          currencyDenomination={currencyDenomination}
        >
          <ResultsBarChart
            data={data}
            formatter={(val: number) => thousandSeparator(round(val, 0))}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsNPATRoe;
