import React, { useState } from "react";
import Card from "../../../atoms/card/Card";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../molecules/iconbutton/IconButton";
import { useCallback } from "react";
import modelApi from "../../../../services/modelApi";
import { startCase } from "../../../../services/utils";
import { formatValue } from "../utils/formatters";
import Banner from "../../../atoms/banner/Banner";
import { wait } from "../../../../lib/wait";

interface Props {
  eventId: string;
  configuration?: ModelAPI.ConfigurationResponse;
  refreshConfiguration: () => void;
}

const format = (
  label: string,
  value: any,
  formatter?: (val: number) => string,
) => {
  if (formatter) {
    return formatter(value);
  }
  if (label === "operatingCostsEnabled") {
    return value ? "True" : "False";
  }

  if (label === "simulationType") {
    return value;
  }
  if (label === "shouldRotateCeo") {
    return value ? "True" : "False";
  }
  if (label === "winningMetrics") {
    return `${value.type} (${value.percentage}%)`;
  }
  if (label === "financialMetrics") {
    return `${value.type}`;
  }
  if (label === "enabledProducts") {
    return value.name;
  }

  return value;
};

const renderLabelAndValue = (formatter?: (val: number) => string) => {
  const LabelAndValue = ([label, value]: [string, any], i: number) => {
    if (Array.isArray(value)) {
      return (
        <DisplayField
          className="mt-2"
          key={i}
          value={
            <VerticalGroup spaceBetweenElements={1}>
              {value.map((v, j) => (
                <Text size="sm" key={j}>
                  {format(label, v, formatter)}
                </Text>
              ))}
            </VerticalGroup>
          }
          label={startCase(label)}
          stretch
          verticalCenter={false}
        />
      );
    }
    return (
      <DisplayField
        key={i}
        value={format(label, value, formatter)}
        label={startCase(label)}
      />
    );
  };
  return LabelAndValue;
};

const ModelConfiguration: React.FC<Props> = ({
  eventId,
  configuration,
  refreshConfiguration,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [displayMessage, setDisplayMessage] = useState<string | null>(null);

  const onResync = useCallback(async () => {
    try {
      setInProgress(true);
      setError(null);
      await modelApi.resyncConfiguration(eventId);
      let iteration = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iteration++;
        const configuration = await modelApi.getModelConfiguration(eventId);
        setDisplayMessage(configuration.progressMessage);
        if (configuration.state !== "initialising") {
          break;
        }
        if (iteration > 100) {
          throw new Error("Timed out building model");
        }
        await wait(5000);
      }
      refreshConfiguration();
      setInProgress(false);
    } catch (e) {
      setError(e.message);
      setInProgress(false);
    }
  }, [eventId, refreshConfiguration]);

  const onUploadResults = useCallback(async () => {
    try {
      setInProgress(true);
      setError(null);
      await modelApi.uploadResults(eventId, 1);
    } catch (e) {
      setError(e.message);
    }
    setInProgress(false);
  }, [eventId]);

  return (
    <VerticalGroup wide spaceBetweenElements={2}>
      {!!error && <Banner type="error" active message={error} />}
      <InlineGroup spaceBetweenElements={2} right block verticalCenter>
        {inProgress && !!displayMessage && <Text>{displayMessage}</Text>}
        <IconButton
          icon="refresh"
          text="Build Model"
          onClick={onResync}
          inProgress={inProgress}
        />
        <IconButton
          icon="upload"
          text="Upload Initial Results"
          onClick={onUploadResults}
          inProgress={inProgress}
        />
      </InlineGroup>
      {configuration && (
        <Card wide>
          {Object.entries(configuration)
            .filter(([label]) => label !== "openingSettings")
            .map(renderLabelAndValue())}
        </Card>
      )}
      <InlineGroup spread verticalCenter block>
        <h2>Opening Balance Sheet</h2>
      </InlineGroup>
      {configuration && (
        <Card wide>
          <VerticalGroup spaceBetweenElements={2}>
            <InlineGroup>
              <VerticalGroup>
                <h4>Assets</h4>
                {Object.entries(
                  configuration.openingSettings.balanceSheet.assets,
                ).map(renderLabelAndValue(formatValue))}
              </VerticalGroup>
              <VerticalGroup>
                <h4>Liability</h4>
                {Object.entries(
                  configuration.openingSettings.balanceSheet.liabilities,
                ).map(renderLabelAndValue(formatValue))}
              </VerticalGroup>
            </InlineGroup>
            <InlineGroup>
              <VerticalGroup>
                <h4>Equity</h4>
                {Object.entries(
                  configuration.openingSettings.balanceSheet.equity,
                ).map(renderLabelAndValue(formatValue))}
              </VerticalGroup>
              <VerticalGroup>
                <h4>Off Balance Sheet</h4>
                {Object.entries(
                  configuration.openingSettings.balanceSheet.offBalanceSheet,
                ).map(renderLabelAndValue(formatValue))}
              </VerticalGroup>
            </InlineGroup>
          </VerticalGroup>
        </Card>
      )}
      <InlineGroup spread verticalCenter block>
        <h2>Opening WSF Profile</h2>
      </InlineGroup>
      {configuration && (
        <Card wide>
          <VerticalGroup spaceBetweenElements={2}>
            {Object.entries(configuration.openingSettings.wsfProfile)
              .sort()
              .map(renderLabelAndValue(formatValue))}
          </VerticalGroup>
        </Card>
      )}
    </VerticalGroup>
  );
};

export default ModelConfiguration;
