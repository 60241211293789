import React, { useCallback, useMemo, useRef } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import TeamPositions from "../positions/TeamPositions";
import useChartStyles from "../../../../hooks/useChartStyles";
import { createLineChartDot } from "../charts/LineChartDot";
import useLineChartRendering from "../charts/useLineChartRendering";
import createCustomTooltip from "../components/CustomTooltip";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  customerSatisfaction: ModelAPI.Reporting.DataAndPositions;
}

const RoundResultsCustomerSatisfaction: React.FC<Props> = ({
  pastRound1,
  teams,
  customerSatisfaction,
}) => {
  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1,
    data: customerSatisfaction.data,
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  const chartStyles = useChartStyles();

  const lines = teams.map((t) => ({
    name: t.teamName,
    key: `team${t.teamId}`,
    colour: t.colour,
    dot: t.dot
      ? t.dot
      : createLineChartDot(
          chartStyles.circleRadius,
          chartStyles.strokeWidth,
          teams,
        ),
  }));

  const shouldRenderLines = useMemo(
    () => context.clicks === maxClicks,
    [context.clicks, maxClicks],
  );

  const chartRef = useRef<any>(null);
  const [
    renderStartTime,
    tooltipVisible,
    setAnimationsCompleted,
    animationCountRef,
  ] = useLineChartRendering({
    data: customerSatisfaction.data,
    chartRef,
    shouldRenderLines,
    lines,
  });

  console.log("Sat.tsx: tooltipVisible: ", tooltipVisible);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Customer Satisfaction">
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <ComposedChart
              ref={chartRef}
              data={customerSatisfaction.data}
              margin={{
                top: 20,
                right: 50,
                left: 50,
                bottom: 10,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                tickLine={false}
                padding={{
                  left: 50,
                  right: 50,
                }}
                tick={{
                  style: {
                    fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
                  },
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                minTickGap={0}
                tickMargin={0}
                tickCount={numberOfTicks + 1}
                domain={[minValue, maxValue]}
                tick={{
                  style: {
                    fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
                  },
                }}
              />
              <Legend
                wrapperStyle={{
                  bottom: 7,
                  left: 0,
                  right: 0,
                  fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
                }}
              />
              <Tooltip
                isAnimationActive={false}
                active={tooltipVisible}
                content={createCustomTooltip(
                  "highest",
                  customerSatisfaction.data,
                )}
              />
              {shouldRenderLines &&
                lines.map((l, idx) => (
                  <Line
                    key={`line-item-${idx}`}
                    name={l.name}
                    type="natural"
                    dataKey={l.key}
                    stroke={l.colour}
                    strokeWidth={chartStyles.strokeWidth}
                    dot={l.dot ? l.dot : { r: chartStyles.circleRadius }}
                    activeDot={{
                      r: chartStyles.circleRadius,
                      stroke: l.colour,
                      strokeWidth: chartStyles.strokeWidth,
                      fill: "transparent",
                    }}
                    isAnimationActive={context.noAnimations ? false : true}
                    animationDuration={context.noAnimations ? 0 : 1000}
                    animationBegin={
                      context.noAnimations
                        ? 0
                        : (renderStartTime ? Date.now() - renderStartTime : 0) +
                          idx * 1000
                    }
                    onAnimationEnd={() => {
                      animationCountRef.current += 1;
                      if (animationCountRef.current === lines.length) {
                        setAnimationsCompleted(true);
                      }
                    }}
                  />
                ))}
              <defs>
                <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="red" stopOpacity={0.1} />
                  <stop offset="100%" stopColor="red" stopOpacity={0.8} />
                </linearGradient>
              </defs>
              <ReferenceArea
                y1={0}
                y2={minValue}
                fill="url(#redGradient)"
                opacity={0.7}
                stroke="none"
              />
            </ComposedChart>
          </ResponsiveContainer>
          {!context.hideTeamPositions &&
            customerSatisfaction.teamPositions &&
            customerSatisfaction.teamPositions.length > 0 && (
              <TeamPositions
                teams={teams}
                teamPositions={customerSatisfaction.teamPositions}
                shouldRender={shouldRenderLines}
              />
            )}
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomerSatisfaction;
